<template>
  <form @submit.prevent="createSection()" action="">
    <ion-row>
      <ion-col size="12">
        <ion-list>
          <ion-item v-if="validationError" lines="none">
            <ion-label className="ion-text-wrap">
              <ion-text color="danger">
                <p v-for="error of v$.$errors" :key="error.$uid">
                  <ion-icon
                    :ios="alertCircleOutline"
                    :md="alertCircleSharp"
                    size="small"
                    style="vertical-align: middle"
                  ></ion-icon
                  >{{ error.$property }}: {{ error.$message }}
                </p></ion-text
              >
            </ion-label>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.subject_group_id.$error }">
            <ion-label position="floating">Subject Group</ion-label>
            <ion-select v-model="subject_group_id" :value="subject_group_id">
              <ion-select-option
                v-for="subject_group of subject_groups"
                :key="subject_group.id"
                :value="subject_group.id"
              >
                {{ subject_group.name }}
              </ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item :class="{ 'color-danger': v$.standard_id.$error }">
            <ion-label position="floating">Standard</ion-label>
            <ion-select v-model="standard_id" :value="standard_id">
              <ion-select-option
                v-for="standard of standards"
                :key="standard.id"
                :value="standard.id"
              >
                {{ standard.name }}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="6">
        <ion-button
          type="button"
          expand="full"
          color="warning"
          class="ion-margin-vertical"
          @click="resetFilters"
        >
          Reset
        </ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-button type="submit" expand="full" class="ion-margin-vertical">
          Save
        </ion-button>
      </ion-col>
    </ion-row>
  </form>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonList,
  IonLabel,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonIcon,
  IonButton,
  IonText,
  loadingController,
  alertController,
} from "@ionic/vue";

// import { alertCircleOutline, alertCircleSharp } from "ionicons/icons";

import { integer, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  emits: ["closeModal"],
  components: {
    IonRow,
    IonCol,
    IonList,
    IonLabel,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonIcon,
    IonButton,
    IonText,
  },
  data() {
    return {
      subject_group_id: null,
      standard_id: null,

      validationError: null,

      // alertCircleOutline,
      // alertCircleSharp,

      // xhrError: {
      //   header: null,
      //   description: null,
      // },
    };
  },

  computed: {
    subject_groups() {
      return this.$store.getters["subjectGroup/allResources"];
    },
    standards() {
      return this.$store.getters["standard/allResources"];
    },
    filters() {
      return this.$store.getters["subject/filters"];
    },
  },

  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    return {
      subject_group_id: {
        integer: helpers.withMessage("can only be integers", integer),
      },
      standard_id: {
        integer: helpers.withMessage("can only be integers", integer),
      },
    };
  },

  methods: {
    setModalState(state) {
      this.modalState = state;
    },

    loader(message) {
      const loading = loadingController.create({
        message: message,
        backdropDismiss: false,
      });

      return loading;
    },

    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        console.log(error.request);
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async createSection() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.validationError = true;
        return;
      }

      let data = {
        subject_group_id: this.subject_group_id,
        standard_id: this.standard_id,
      };

      const spinner = await this.loader("Saving...");
      spinner.present();

      try {
        await this.$store.dispatch("subject/filters", data);
        spinner.dismiss();
        this.$emit("closeModal");
      } catch (error) {
        spinner.dismiss();

        this.setErrorResponse(error);

        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );

        alertDialog.present();

        this.password = null;
        this.validationError = null;
      }
    },

    async fetchSubjectGroups() {
      await this.$store.dispatch("subjectGroup/allResources");
      this.subject_group_id = this.filters.subject_group_id;
    },
    async fetchStandards() {
      await this.$store.dispatch("standard/allResources");
      this.standard_id = this.filters.standard_id;
    },

    resetFilters() {
      this.subject_group_id = null;
      this.standard_id = null;
    },
  },

  created() {
    this.fetchSubjectGroups();
    this.fetchStandards();
  },
};
</script>